import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 80px 40px;
  max-width: 1200px;
  margin: 0 auto;
`;

export const Profile = styled.div``;

export const Title = styled(motion.h1)`
  text-transform: uppercase;
  font-size: 32px;
`;

export const Tagline = styled(motion.div)`
  margin-bottom: 30px;
  width: 400px;
  line-height: 1.5rem;
  white-space: pre-wrap;
  text-transform: uppercase;
  letter-spacing: 0.49rem;
  color: #444;
`;

export const Bio = styled(motion.div)`
  margin-bottom: 30px;
  width: 400px;
  line-height: 1.5rem;
  white-space: pre-wrap;
`;

export const Stack = styled(motion.div)`
  margin-bottom: 20px;
  line-height: 1.5rem;
  width: 400px;
`;

export const Contact = styled(motion.div)``;

export const ExternalLink = styled.a`
  display: block;
  margin-bottom: 5px;

  &:hover {
    color: #eee;
  }
`;

export const Label = styled(motion.span)`
  display: block;
  text-transform: uppercase;
  color: #444;
  font-size: 11px;
  line-height: 1.5rem;
`;
