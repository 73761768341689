import styled from 'styled-components';
import { motion } from 'framer-motion';

export const ProjectList = styled.ul``;

export const Project = styled.a`
  text-align: right;
  display: block;
  margin-bottom: 20px;

  h3 {
    font-size: 32px;
    text-transform: uppercase;
  }

  span {
    display: block;
    margin-bottom: 5px;
  }

  &:hover {
    color: #fff;
  }
`;

export const Poster = styled(motion.div)`
  overflow: hidden;
`;
