import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import Head from 'u9/components/Head';
import ProjectList from 'components/ProjectList';

import { HomePageProps } from './types';

import {
  Wrapper,
  Label,
  Profile,
  Title,
  Tagline,
  Bio,
  Stack,
  Contact,
  ExternalLink,
} from './styled';

let initialRender = true;

const HomePage: React.FunctionComponent<HomePageProps> = ({
  head,
  title,
  tagline,
  bio,
  stack,
  email,
  socialLinks,
  resume,
  projects,
}) => {
  React.useEffect(() => {
    initialRender = false;
  });

  const transitionProps = !initialRender
    ? () => ({})
    : (delay: number) => ({
        initial: { opacity: 0, y: 10 },
        animate: { opacity: 1, y: 0 },
        transition: { delay, duration: 1, ease: 'easeOut' },
      });

  return (
    <motion.div
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -50 }}
      transition={{ duration: 0.5, ease: 'easeOut' }}
    >
      <Head {...head} />
      <AnimatePresence>
        <Wrapper>
          <Profile>
            <Title {...transitionProps(0.2)}>{title}</Title>
            <Tagline {...transitionProps(0.4)}>{tagline}</Tagline>
            <Bio {...transitionProps(0.6)}>
              <Label>- What I do</Label>
              <p>{bio}</p>
            </Bio>
            <Stack {...transitionProps(0.8)}>
              <Label>- Current stack</Label>
              <p>{stack}</p>
            </Stack>
            <Contact {...transitionProps(0.8)}>
              <Label>- Contact</Label>
              <ExternalLink href={`mailto:${email}`} target="_blank">
                Email
              </ExternalLink>
              {socialLinks.map(({ displayText, url }) => (
                <ExternalLink key={displayText} href={url} target="_blank">
                  {displayText}
                </ExternalLink>
              ))}
              <ExternalLink href={resume.url} target="_blank">
                Resume
              </ExternalLink>
            </Contact>
          </Profile>
          <ProjectList projects={projects} />
        </Wrapper>
      </AnimatePresence>
    </motion.div>
  );
};

export default HomePage;
