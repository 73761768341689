import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Link from 'next/link';
import Image from 'next/image';

import { Project, Poster } from './styled';

interface ProjectListProps {
  projects: [
    {
      slug: string;
      title: string;
      client: string;
      poster: {
        url: string;
      };
    }
  ];
}

let inititalRender = true;

const ProjectList: React.FunctionComponent<ProjectListProps> = ({
  projects,
}) => {
  const [activeLink, setActiveLink] = React.useState<string>();

  React.useEffect(() => {
    inititalRender = false;
  });

  const transitionProps = !inititalRender
    ? () => ({})
    : (i: number) => ({
        initial: { opacity: 0, y: 10 },
        animate: {
          opacity: 1,
          y: 0,
        },
        transition: {
          delay: 0.2 + i * 0.1,
          duration: 1,
          ease: 'easeOut',
        },
      });

  return (
    <AnimatePresence>
      <ul onMouseLeave={() => setActiveLink('')}>
        {projects.map(({ slug, title, client, poster }, index) => (
          <motion.li key={title} {...transitionProps(index)}>
            <Link href={`/project/${slug}`} passHref>
              <Project onMouseEnter={() => setActiveLink(title)}>
                <h3>{title}</h3>
                <span>{client}</span>
                <Poster
                  initial={{ height: 0, opacity: 0 }}
                  animate={activeLink === title ? 'open' : 'closed'}
                  variants={{
                    open: { height: 'auto', opacity: 1 },
                    closed: { height: 0, opacity: 0 },
                  }}
                  transition={{ ease: 'easeOut', duration: 0.5 }}
                >
                  <Image
                    src={poster.url}
                    alt={title}
                    width={400}
                    height={200}
                    objectFit="cover"
                  />
                </Poster>
              </Project>
            </Link>
          </motion.li>
        ))}
      </ul>
    </AnimatePresence>
  );
};

export default ProjectList;
